import * as React from 'react'
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share'
import SVGIcon from './SVGIcon'

type ShareButtonsProps = {
    url: string;
    title: string;
}
const shareIconClasses = "h-4 lg:h-5 w-auto object-contain text-accent transition fill-current hover:text-white hover:opacity-75"
const shareIconStyles = { width: "30px", display: "flex", justifyContent: "center", marginLeft: "8px" }
export default function ShareButtons({ url,title }: ShareButtonsProps) {
    console.log('URL', url)
    return (
        <div className="block--post-share border border-white border-solid items-center rounded-md border-opacity-20 px-5 py-3 flex">
            <div className="font-bold text-2xs mr-2">Share this resource  </div>
            <div className="flex -mx-1 items-center">
                <FacebookShareButton style={shareIconStyles} title={title} url={url} ><SVGIcon iconName="facebook" className={shareIconClasses} /></FacebookShareButton>
                <TwitterShareButton style={shareIconStyles} title={title} url={url} ><SVGIcon iconName="twitter" className={shareIconClasses} /></TwitterShareButton>
                <LinkedinShareButton style={shareIconStyles} title={title} url={url} ><SVGIcon iconName="linkedin" className={shareIconClasses} /></LinkedinShareButton>
            </div>
        </div>

    )
}