import * as React from 'react'
import { IGatsbyImageData, GatsbyImage, getImage } from 'gatsby-plugin-image'
import ShareButtons from '../ShareButtons'

interface PostHeaderProps {
    title: string;
    date: string;
    banner: IGatsbyImageData;
    author: string;
    categories: string[];
    url: string;
}

export default function PostHeader({ title, date, banner, url, categories }: PostHeaderProps) {
    const gatsbyImgBanner = getImage(banner)
    console.log('CAT', categories)
    return (
        <section className="section--postheader relative is--dimmed-gradient overflow-hidden">
            <div className="image image--center z-10">
                <GatsbyImage image={gatsbyImgBanner} alt={`random`} />
            </div>
            <div className="container--padded-sm pt-resp-24-56 pb-resp-16-48 relative z-20">

                <h1 className="text-h2  leading-snug text-center mb-12">{title}</h1>
                <div className="flex flex-col justify-center items-center">

                    <div className="block--post-date mb-12 flex">
                        <div className="font-bold text-xs">{date}</div>
                        <div className="font-bold text-xs">&nbsp;in&nbsp;</div>
                        <div className="font-bold text-xs text-accent flex">
                            {categories.map((cat, i) => (
                                <React.Fragment key={i}>
                                    {i > 0 && (
                                        <span  className="text-white text-opacity-30">&nbsp;|&nbsp;</span>
                                    )}
                                    <span  className="block capitalize">{cat}</span>
                                </React.Fragment>
                            )
                            )}
                        </div>
                    </div>
                    <ShareButtons title={title} url={url} />
                </div>
            </div>
        </section>
    )
}