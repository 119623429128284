import * as React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import Layout from '../components/layout'

import config from '../../config/website'
import PostHeader from '../components/sections/PostHeader'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import ShareButtons from '../components/ShareButtons'
import SimilarPosts from '../components/SimilarPosts'

export default function BlogPost({ data, ...props }) {
  const mdx = data.mdx
  const { title, banner, keywords, slug, categories, date, post} = mdx
  const author = "Majiska Team"

  const blogPostUrl = `${config.siteUrl}${slug}`
  const url = props.location.href;
  //console.log('URL', url,'MDX FIELDS',mdx.fields)
  return (
    <Layout>
      <SEO
        title={title}
        description={mdx.post.childMdx.description}
        isBlogPost
        postData={mdx}
        metaImage={mdx.banner.gatsbyImageData.images.fallback.src}
      />
      <PostHeader
        title={title}
        date={date}
        banner={banner}
        author={author}
        categories={categories}
        url={url}
      />
      <div className="container--padded-sm post__content -mt-6 md:-mt-12 pb-resp-6-14 relative z-10">
        <MDXRenderer>{post.childMdx.body}</MDXRenderer>

      </div>
      <div className="container--padded-sm pb-resp-16-24">
        <div className=" max-w-max"> <ShareButtons title={title} url={url} /></div>
      </div>
      <div className="container--padded pb-resp-16-32 pt-resp-5-8">
        <h3 className="text-center text-3xl pb-resp-12-20">More resources like this</h3>
        <SimilarPosts keywords={keywords} categories={categories} currentPostSlug={slug} />
      </div>


    </Layout>
  )
}

export const pageQuery = graphql`
    query($id: String!) {

    mdx:contentfulBlogPost(id: {eq: $id}) {
      title
      slug
      categories:category
      date:createdAt(formatString: "MMMM DD, YYYY")
      keywords:tag
      post {
        childMdx {
          body
          description:excerpt(pruneLength:160)
          timeToRead
          slug
        }
      }
      banner:featuredImage {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`

/*
export const pageQuery = graphql`
    query($id: String!) {
       
    site {
      siteMetadata {
        keywords
      }
    }
    mdx(fields: {id: {eq: $id}}) {
      fields {
        title    
        description
        date(formatString: "MMMM DD, YYYY")
        author  
        slug
        categories
        keywords
        banner{
            childImageSharp {
            gatsbyImageData(layout:FULL_WIDTH)
            }
        }
      }
      body
      timeToRead
    }
  }
`
*/