import * as React from 'react'
import _, { includes, orderBy } from 'lodash'
import { useStaticQuery, graphql } from 'gatsby'
import PostCard from '../components/PostCard'
function getPostsFromQuery({ edges }) {


    return _.map(edges, ({ node: post }, i) => {
        return (
            {
                title: post.title,
                slug: post.slug,
                categories: post.category,
                keywords: post.tag,
                image: post.banner,
                timeToRead:post.post.childMdx.timeToRead
            }
        )
    })

}

const withConstructor = constructor => o => ({
    // create the delegate [[Prototype]]
    __proto__: {
        // add the constructor prop to the new [[Prototype]]
        constructor
    },
    // mix all o's props into the new object
    ...o
});

function similarPostsFactory() {
    let maxPosts: number = 6;
    let categories: string[] = [];
    let keywords: string[] = [];
    let posts: string = '';
    //this.posts = '';
    return {
        setPosts: function (argposts, currentPostSlug: string = '') {
            this.posts = argposts.filter(
                (post) => post.slug !== currentPostSlug
            )
            return this;
        },
        setMaxPosts: function (num) {
            this.maxPosts = num;
            return this;
        },
        setCategories: function (cats) {
            this.categories = cats;
            return this;
        },
        setKeywords: function (keywords) {
            this.keywords = keywords;
            return this;
        },
        getPosts: function () {
            const { maxPosts, categories, keywords, posts } = this;
            const identityMap = {};

            if (!!keywords == false || keywords.length === 0) {
                console.error('SimilarpostsFactory: Tags not provided, use setTags().')
                return [];
            }
            function getSlug(post) {
                return post.slug
            }
            function addToMap(post) {
                const slug = getSlug(post);
                if (!identityMap.hasOwnProperty(slug)) {
                    identityMap[slug] = {
                        post: post,
                        points: 0
                    }
                }
            }
            // (7.) For category matches, we add 2 points
            function addCategoryPoints(post, category) {
                const categoryPoints = 2;
                const slug = getSlug(post);


                post.categories.forEach((aTag) => {
                    if (includes(category, aTag)) {
                        identityMap[slug].points += categoryPoints;
                    }
                })
            }

            // (8.) For tags matches, we add 1 point
            function addTagsPoints(post, tags) {
                const tagPoint = 1;
                const slug = getSlug(post);

                post.keywords.forEach((aTag) => {
                    if (includes(tags, aTag)) {
                        identityMap[slug].points += tagPoint;
                    }
                })
            }

            function getIdentityMapAsArray() {
                return Object.keys(identityMap).map((slug) => identityMap[slug]);
            }

            // (6.) Map over all posts, add to map and add points
            for (let post of posts) {
                addToMap(post);
                addCategoryPoints(post, categories);
                addTagsPoints(post, keywords)
            }

            // (9.) Convert the identity map to an array
            const arrayIdentityMap = getIdentityMapAsArray();

            // (10.) Use a lodash utility function to sort them 
            // by points, from greatest to least
            const similarposts = orderBy(
                arrayIdentityMap, ['points'], ['desc']
            )

            // (11. Take the max number posts requested)
            return similarposts.splice(0, maxPosts);
        }


    }
}

export default function SimilarPosts(props) {
    const data = useStaticQuery(graphql`
        query SimilarPosts {
            posts: allContentfulBlogPost(sort: {fields: createdAt, order: DESC}) {
                categories: distinct(field: category)
                edges {
                  node {
                    id
                    title
                    slug
                    date: createdAt(formatString: "MMMM DD, YYYY")
                    category
                    tag
                    banner: featuredImage {
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                    post {
                      childMdx {
                        excerpt(pruneLength: 160)
                        timeToRead
                      }
                    }
                  }
                }
              }
        }
    
    `)
    const { categories, keywords, currentPostSlug } = props;

    const simPosts = Object.create(similarPostsFactory());
    simPosts.setPosts(getPostsFromQuery(data.posts), currentPostSlug).setMaxPosts(3).setCategories(categories).setKeywords(keywords);

    const getSimPosts = simPosts.getPosts()

    return (
        <>
            <div className={`${props.className} flex flex-wrap -mx-3`}>
            {simPosts && _.map(getSimPosts, ({post}, i) => {
                    return (
                        <PostCard timeToRead={post.timeToRead} key={i} title={post.title} slug={`/resources/${post.slug}`} image={post.image.gatsbyImageData} description={post.description} categories={post.categories} />
                    )
                })}
            </div>
        </>
    )
}